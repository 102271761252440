.has-actions-panel {
    position: relative;
    text-align: right !important;

    & > .actions-panel {
        right: 0;
    }
}
.actions-panel {
    position: absolute;
    min-width: 240px;
    right: 0;
    z-index: 10;

    &.is-open {
        z-index: 100;
    }
    hr.panel-block {
        margin: 0;
        padding: 0;
        height: 6px;
    }

    label.panel-block {
        border-bottom: none;
        font-weight: 700;
        justify-content: center;
        padding-bottom: 0;
        pointer-events: none;
    }

    .panel-block:not(.is-disabled) {
        .disabled-message {
            display: none;
        }
    }

    .panel-block.is-disabled {
        .disabled-message {
            display: none;
            position: absolute;
            right: 5px;
            top: 5px;
            min-width: 300px;
            max-width: 400px;
        }

        &:hover {
            .disabled-message {
                display: block !important;
            }
        }
    }
}

.field.has-addons {
    .actions-panel {
        position: relative;

        .icon {
            border: solid 1px #dbdbdb;
            padding-top: calc(0.5em - 1px);
            padding-bottom: calc(0.5em - 1px);
            padding-left: 1em;
            padding-right: 1em;
            font-size: 1rem;
            height: 2.5em;
        }

        .panel {
            position: absolute;
            right: 0;
            min-width: 240px;
        }
    }
}
