.selectbox {
    span[class*="-indicatorSeparator"] {
        visibility: hidden;
    }

    div[class*="-control"] {
        border-width: 1px !important;
        border-style: solid !important;
        border-color: #dbdbdb !important;
        border-radius: 4px;
        box-shadow: inset 0 0.0625em 0.125em rgba(10, 10, 10, 0.05) !important;
    }

    &:hover {
        div[class*="-control"] {
            border-color: #b5b5b5 !important;
        }
    }

    &.is-danger {
        div[class*="-control"] {
            border-color: #f14668 !important;
        }
    }

    div[class*="-ValueContainer"] {
        padding: 5px 8px 4px !important;
    }


    &.is-menu-size-6 {
        div[class*="-menu"] {
            div[class*="-option"] {
                font-size: 0.75rem !important;
                padding: 4px 12px !important;
            }
        }
    }

    &.is-fullwidth {
        width: 100%;
    }
}
