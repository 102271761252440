.user-listing-line {
    .id {
        width: 70px;
    }
    .name {
        width: 240px;
    }
    .admin {
        width: 100px;
    }
    .enabled {
        width: 70px;
    }
    .actions {
        opacity: 0;
        position: relative;
        transition: all 0.3s ease-in-out;
        width: 70px;

        .icon {
            z-index: 2;
        }
        .actions-panel {
            min-width: 180px;
        }
    }

    &:hover {
        .actions {
            opacity: 1;
        }
    }
}
