.format-listing-line {
    .id {
        width: 70px;
    }
    .type {
        width: 170px;
    }
    .actions {
        opacity: 0;
        transition: all 0.3s ease-in-out;
    }

    &:hover {
        .actions {
            opacity: 1;
        }
    }
}

.formatcode-listing-line {
    .id {
        width: 70px;
    }
    .code {
        width: 120px;
    }
    .main-format {
        width: 210px;
    }
    .actions {
        opacity: 0;
        transition: all 0.3s ease-in-out;
    }

    &:hover {
        .actions {
            opacity: 1;
        }
    }
}

.formatcode-add-button {
    width: 100%;

    .button {
        height: 2.46em;
    }
}
