.react-date-picker {
    display: flex !important;
}

.react-date-picker__wrapper {
    border-width: 1px !important;
    border-style: solid !important;
    border-color: #dbdbdb !important;
    border-radius: 4px;
    padding: 4px 11px;
    font-size: 16px;
    font-family: var(--font-input);
    box-shadow: inset 0 0.0625em 0.125em rgba(10, 10, 10, 0.05);

    &:hover {
        border-color: #b5b5b5 !important;
    }
}

.react-date-picker.is-danger {
    .react-date-picker__wrapper {
        border-color: var(--color-danger) !important;
    }
}
.react-date-picker--open {
    .react-date-picker__wrapper {
        border-color: #3273dc !important;
        box-shadow: rgba(50, 115, 220, 0.25) 0px 0px 0px 2px;
    }
}

.react-date-picker__inputGroup {
    padding: 0 !important;

    input, span {
        font-family: var(--font-input) !important;
        font-weight: 400;
        font-size: 16px;
        color: #363636;
    }
}

.react-date-picker__button {
    padding: 2px 6px;

    i {
        font-size: 18px;
    }
}
