.table-categories {
    .id {
        width: 60px;
    }
    .channels {
        width: 230px;
        text-align: center;
    }
    .actions {
        width: 10%;
    }
}

.table-category-channels {
    .actions {
        width: 10%;
        text-align: right;
    }
}
