.switch-container {
}

.switch {
    align-items: center;
    border-radius: 2px;
    border: solid 1px var(--color-readOnly);
    display: flex;
    height: 30px;
    overflow: hidden;
    padding: 2px;
    position: relative;
    width: 94px;

    &.small {
        height: 20px;
        width: 62px;
    }

    .button {
        position: absolute;
        top: 2px;
        left: 2px;
        transition: var(--transition-default);
        border-color: var(--color-readOnly);
        background-color: var(--color-readOnly);
        border-radius: 2px;
        height: 24px;
        cursor: pointer;

        &:focus {
            box-shadow: none;
        }
    }

    &.small {
        .button {
            height: 14px;
        }
    }

    .button::before {
        content: attr(data-label-enabled);
        position: absolute;
        right: 25px;
        padding-right: 10px;
        opacity: 0;
        transition: var(--transition-default);
    }
    .button::after {
        content: attr(data-label-disabled);
        position: absolute;
        left: 25px;
        padding-left: 10px;
        opacity: 1;
        transition: var(--transition-default);
    }

    &.enabled {
        .button {
            left: 64px;
            background-color: var(--color-bold);
            border-color: var(--color-bold);

            &::before {
                opacity: 1;
            }
            &::after {
                opacity: 0;
            }
        }

        &.small {
            .button {
                left: 32px;
            }
        }
    }
}
