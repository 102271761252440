.layout-main-container {
    top: 24px;
}

.layout-main-header {
    background-color: var(--color-bold);
    color: var(--color-white);
    font-size: var(--font-24px);
    height: 65px;
    position: sticky;
    top: 0;
    z-index: 10000;

    a {
        color: var(--color-white);

        &:hover {
            color: var(--color-highlight);
        }
    }
}

.layout-main-content {
    margin-top: 26px;
    position: relative;
    z-index: 1;
}

.layout-main-sidebar {
    position: sticky;
    top: 77px;
    width: 100%;
    z-index: 0;

    .column:first-child {
        background-color: var(--color-white);
    }

    .menu-list {
        li.has-button {
            display: flex;
            align-items: center;

            & > *:first-child {
                flex-grow: 2;
            }
        }
    }
}

 .layout-main-footer {
     position: fixed;
     bottom: 0;
     right: 0;
     padding: 0 var(--margin-bitof) var(--margin-bitof) 0;
     font-size: var(--font-12px);
 }
