.table-collections {
    .export-format {
        width: 20%;
    }
    .actions {
        width: 10%;
    }
}
.table-collection-items {
    width: max-content;
    .when-extended {display:table-cell;}

    .barcode {
        width: 230px;
    }
    .artist {
        width: 200px;
    }
    .album {
        width: 700px;
    }
    .blerp {
        width: 300px;
    }
    .format {
        width: 170px;
    }
    .quantity {
        width: 65px;
    }
    .price {
        width: 85px;
    }
    .actions {
        width: 65px;
    }

    &.summarized {
        width: auto;
.when-extended {display:none;}

        .barcode {
            width: 230px;
            // width: 17%;
        }
        .artist {
            width: 20%;
        }
        .format {
            width: 15%;
        }
        .quantity {
            width: 6%;
        }
        .price {
            width: 8%;
        }
        .actions {
            width: 8%;
        }
    }
}

.not-exportable {
    background-color: #f3f3f3;

    &.indicator {
        width: 15px;
        height: 15px;
        border: solid 1px rgb(219,219,219);
    }
}

.collection-item-adder-results {
    overflow:hidden;

    .content {
        max-height: 40vh;
        overflow-y: auto;
    }
}
