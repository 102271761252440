.table-inventory {
    .actions {
        width: 65px;

        .content {
            justify-content: flex-end;
        }
        i {
            z-index: 11;
        }
        a {
            z-index: 11;
        }
        .actions-panel {
            min-width: 180px;
        }
    }

    .barcode {
        width: 180px;
    }
    .format {
        width: 115px;
    }
    .price {
        width: 100px;
        z-index: 11;
    }
    .album {
        width: 430px;
    }
}

.filter-inventory {
    position: relative;
    z-index: 20;
}

.table-inventory-categories {
    .id {
        width: 60px;
    }
    .actions {
        width: 65px;
    }
}
