.import-listing-line {
    .id {
        width: 50px;
    }
    .filename {
        max-width: 300px;
        // width: 300px;
    }
    .adapter {
        width: 100px;
    }
    .total-records {
        width: 85px;
    }
    .num-processed {
        width: 145px;
    }
    .percentage {
        width: 120px;
    }
    .last-updated {
        width: 110px;
    }
    .expander {
        position: relative;
        width: 50px;

        i {
            transition: all 0.3s ease-in-out;
            position: absolute;
            right: 13px;
        }
    }
    .error-type {
        display: inline-block;
        width: 80px;
    }
    .errors {
        display: none;
    }


    &.is-expanded {
        .expander {
            i {
                transform: rotate(-540deg);
            }
        }
        .errors {
            display: table-row;
        }
    }

    .error-type {
        width: 100px;
    }
}
