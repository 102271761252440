.dropdown {
    div[class*="-ValueContainer"] {
        padding: 5px 8px !important;
    }


    &.is-input {
        width: 100%;

        .dropdown-trigger,
        .dropdown-menu {
            width: 100%;
        }

        button {
            padding-left: calc(0.75em - 1px);
            padding-right: calc(0.75em - 1px);
            width: 100%;

            .control {
                width: 100%;
            }
        }

        .dropdown-content {
            max-height: 250px;
            overflow-y: scroll;
        }
    }
}

.hoveredtitle {
    position: relative;

    .tag {
        background-color: var(--color-highlight);
        bottom: -20px;
        color: var(--color-bold);
        left: 20%;
        opacity: 0;
        position: absolute;
        transition: var(--transition-default);
        transition-delay: 0s;
    }

    &:hover {
        .tag {
            opacity: 1;
            transition-delay: .5s;
        }
    }
}
