:root {
    // Colors
    --color-body-c:         218, 27%;
    --color-body-l:         94%;
    --color-body:           hsl(var(--color-body-c), var(--color-body-l));
    --color-bold-c:         220, 16%;
    --color-bold-l:         36%;
    --color-bold:           hsl(var(--color-bold-c), var(--color-bold-l));
    --color-danger:         red;
    --color-highlight-c:    193, 43%;
    --color-highlight-l:    67%;
    --color-highlight:      hsl(var(--color-highlight-c), var(--color-highlight-l));
    --color-highlight-dark: hsl(var(--color-highlight-c), calc(var(--color-highlight-l) - 20%));
    --color-highlight-light: hsl(var(--color-highlight-c), calc(var(--color-highlight-l) + 8%));
    --color-lowkey:         #CCCCCC;
    --color-lowerkey:       #666666;
    --color-muted-c:        0, 0%;
    --color-muted-l:        62%;
    --color-muted:          hsl(var(--color-muted-c), var(--color-muted-l));
    --color-muted-light:    hsl(var(--color-muted-c), calc(var(--color-muted-l) + 10%));
    --color-readOnly:       #DEDEDE;
    --color-white:          #FFFFFF;

    // Font Families
    --font-body:    'Ubuntu', sans-serif;
    --font-input:   system-ui 'Segoe UI', 'Roboto', 'Oxygen', 'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue', 'Helvetica', 'Arial', sans-serif;
    --font-fixed:   'Courier New';
    --font-heading: 'Abel', serif;
    // Font Sizes
    --font-baseSize: 18;
    --font-defaultSize: 18px;
    --font-12px: .666666667rem;
    --font-14px: calc(14 / var(--font-baseSize)) + rem;
    --font-16px: calc(16 / var(--font-baseSize)) + rem;
    --font-18px: calc(18 / var(--font-baseSize)) + rem;
    --font-22px: 1.222222222rem;
    --font-24px: 1.333333333rem;
    --font-26px: calc(26 / var(--font-baseSize)) + rem;
    --font-30px: 1.666666667rem;
    // --font-30px: calc(30 / var(--font-baseSize)) + rem;
    --font-32px: calc(32 / var(--font-baseSize)) + rem;
    --font-40px: 2.222222222rem;
    // --font-40px: calc(40 / var(--font-baseSize)) + rem;
    // Font Weights
    --font-weight-lighter: 100;
    --font-weight-light:   200;
    --font-weight-normal:  400;
    --font-weight-bold:    600;

    // Margins
    --margin-big:      90px;
    --margin-major:    60px;
    --margin-minor:    30px;
    --margin-spaced:   20px;
    --margin-decent:   15px;
    --margin-standard: 10px;
    --margin-bitof:    5px;

    --transition-default: all 0.18s ease-in-out;
}
