.tabscontainer {
    .tabscontainer-tab {
        .indicator {
            color: var(--color-muted-light);
            transition: var(--transition-default);
        }

        &.is-dirty {
            .indicator {
                // color: var(--color-highlight-dark);
            }
        }

        &.has-errors {
            .indicator {
                color: var(--color-danger);
            }
        }
    }
}
