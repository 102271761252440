html {
    background-color: var(--color-body);
    min-height: 100vh;
    scroll-behavior: smooth;
}

body {
    // background-color: var(--color-body);
    color: var(--color-bold);
    font-family: var(--font-body);
    font-size: var(--font-defaultSize);
    font-weight: 300;
    line-height: var(--font-24px);
    min-height: 100vh;
}
#root {
    min-height:100vh;
}

pre {
    background-color: var(--color-readOnly);
    border-radius: 4px;
    border: solid 1px var(--color-lowkey);
    clear: both;
    font-family: var(--font-fixed);
    padding: var(--margin-bitof);
}

a {
    color: var(--color-highlight-dark);
}

p {
    &.title {
        color: var(--color-bold);
        font-family: var(--font-heading);
    }
}

legend {
    font-family: var(--font-heading);
    font-size: var(--font-24px);
    font-weight: var(--font-weight-bold);
}

label > input {
    margin-right: var(--margin-bitof);
}

.full-page {
    min-height: 100vh;
}

.hidden {
    display: none;
}
.has-shadow {
    box-shadow: 0 0.5em 1em -0.125em rgba(10, 10, 10, 0.1), 0 0px 0 1px rgba(10, 10, 10, 0.02);
}
.highlight,
.is-highlighted {
    color: var(--color-highlight);

    &.dark {
        color: var(--color-highlight-dark);
    }
}
.danger {
    color: var(--color-danger);
}
.muted {
    color: var(--color-muted);
}
.white {
    color: var(--color-white);
}
.bold {
    font-weight: var(--font-weight-bold);
}
.strong {
    font-weight: var(--font-weight-normal);
}
.fixed {
    font-family: var(--font-fixed);
}
.dim {
    opacity: .3;
}

::placeholder {
    color: var(--color-muted-light);
}

code {
    font-family: var(--font-fixed);
    font-weight: var(--font-weight-bold);
}

.info-text {
    color: var(--color-lowerkey);
    font-size: var(--font-16px);
}

.clipboard {
    cursor: pointer;
    transition: var(--transition-default);

    &:hover {
        color: var(--color-highlight);
    }
}
.has-clipboard-hidden {
    .clipboard {
        opacity: 0;
        transition: var(--transition-default);
    }
    &:hover .clipboard {
        opacity: 1;
    }
}

.editable-title {
    .displaying {
        .title {
            // .icon {
            //     opacity: 0;
            // }

            // &:hover .icon {
            //     opacity: 1;
            // }
        }
    }
    .editing {
        input {
            font-family: var(--font-heading);
            font-size: 2rem;
            font-weight: var(--font-weight-bold);
            height: auto;
            padding-bottom: 0;
            padding-top: 0;
        }
    }
}
.input.is-title {
    font-family: var(--font-heading);
    font-size: 2rem;
    font-weight: var(--font-weight-bold);
    height: auto;
    padding-bottom: 0;
    padding-top: 0;
}

h1, h2, h3, h4, h5, h6 {
    font-family: var(--font-heading);
    color: var(--color-bold);
}
h1 {
    font-size: var(--font-40px);
}
h2 {
    font-size: var(--font-32px);
}
h3 {
    font-size: var(--font-26px);
}

.is-clickable {
    pointer-events: all !important;
}

.is-disabled {
    color: #ccc;
    cursor: default !important;
}

.is-borderless {
    border-width: 0;

    td {
        border-width: 0;
    }
}

.is-absolute {
    position: absolute !important;
}

.is-shadowed {
    box-shadow: 0 0.5em 1em -0.125em rgba(10, 10, 10, 0.1), 0 0px 0 1px rgba(10, 10, 10, 0.02);
}

.is-inherited-color {
    color: inherit !important;
}

.is-clipped.has-ellipsis {
    text-overflow: ellipsis;
}

.has-background-transparent {
    background: transparent !important;
}

.truncated {
    white-space: nowrap;
    overflow: hidden !important;
    text-overflow: ellipsis;
}

.button {
    &.is-dark {
        background-color: var(--color-bold);
        color: var(--color-highlight-light);

        &:hover {
            background-color: var(--color-bold);
            color: var(--color-white);
        }
    }

    &.is-secondary {
        background-color: var(--color-readOnly);
    }

    &.is-highlighted {
        background-color: var(--color-highlight);
        border-color: transparent;
        color: var(--color-bold);

        &:hover {
            color: var(--color-white);
        }
    }

    &.is-ghost {
        background-color: transparent;
        border-color: transparent;
    }
}

.tag {
    &.is-highlighted {
        background-color: var(--color-highlight);
        color: var(--color-white);
    }
}

.textarea {
    &.is-static {
        background-color: transparent;
        border-color: transparent;
        box-shadow: none;
        padding-left: 0;
        padding-right: 0;
    }
}

.table-contributors {
    .role {
        width: 30%;
    }
    .position {
        width: 20%;
    }
}

.modal {
    &.is-medium {
        .modal-content {
            width: 900px;
            max-width: 95vw;
        }
    }
}

.menu-list {
    li:not(.no-hover):hover {
        background-color: whitesmoke;
        border-radius: 2px;
    }

    .is-sublabel {
        margin-left: 12px;
    }

    .button {
        &.is-small {
            height: auto;
            padding: calc(.2em - 1px) .5em;
        }

        &:focus {
            box-shadow: none !important;
        }
    }
}
